import {
  Headline,
  ImageHeader,
  MainContent,
  PanelInfos,
  Section,
  TwoColumns,
} from "../templates/common/Components.jsx";
import { Paragraph, SectionTitle } from "../components/typography.jsx";
import Tooltip, { CustomTooltipContent } from "../components/Tooltip.jsx";
import { color, font, is, styled, theme } from "../util/style.js";
import { useExpiredVouchers, useVouchers } from "../services/vouchers.js";

import Container from "../templates/common/Container.jsx";
import ContentLoading from "../components/ContentLoading.jsx";
import { DownloadPdfVoucher } from "../components/DownloadPdfVoucher.jsx";
import Icon from "../components/Icon.jsx";
import LoggedInTemplate from "../templates/LoggedInTemplate.jsx";
import { format } from "../../common/date.js";
import { formatCurrency } from "../../common/currency.js";
import header from "../img/services_bandeau.jpg";
import header2x from "../img/services_bandeau@2x.jpg";
import headerMobile from "../img/services_bandeau_mobile.jpg";
import headerMobile2x from "../img/services_bandeau_mobile@2x.jpg";
import { usePermissions } from "../services/permissions.js";
import { useSelfMetaData } from "../services/self.js";
import voucher100 from "../img/voucher_100.jpg";
import voucher150 from "../img/voucher_150.jpg";
import voucher200 from "../img/voucher_200.jpg";
import voucher50 from "../img/voucher_50.jpg";
import voucherPresentation from "../img/voucher_photo.jpg";
import vouchersStackedImage from "../img/vouchers_montage.png";

const VouchersList = styled(Container)`
  display: grid;
  justify-content: center;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fill, 260px);
`;

const VOUCHERS_IMAGES = {
  50: voucher50,
  100: voucher100,
  150: voucher150,
  200: voucher200,
};
const VOUCHERS_AMOUNTS = Object.keys(VOUCHERS_IMAGES).map((x) =>
  parseInt(x, 10)
);

const Voucher = styled(function ({
  downloaded,
  downloadedAt,
  used,
  usedAt,
  className,
  voucherCode,
  expirationDate,
  amount,
  index,
}) {
  const [hasPermission] = usePermissions();
  return (
    <div className={className}>
      <img
        src={VOUCHERS_IMAGES[amount] || voucher100}
        alt=""
        className="Voucher-img"
      />
      <span className="Voucher-index">{String(index).padStart(2, "0")}</span>
      <div className="Voucher-actions">
        <span
          className={
            "Voucher-status" + (Boolean(used) ? " Voucher-status--used" : "")
          }
        >
          {Boolean(used)
            ? "Déjà utilisé par votre client"
            : Boolean(downloaded)
            ? "Déjà téléchargé"
            : "Vous n'avez pas encore téléchargé cette offre"}
          {Boolean(usedAt || downloadedAt) && (
            <>
              <br />
              le{" "}
              <span className="Voucher-status-date">
                {format(usedAt || downloadedAt)}
              </span>
            </>
          )}
        </span>
        {hasPermission("vouchers:download") && Boolean(!used && amount) && (
          <DownloadPdfVoucher
            voucher={{
              voucherCode,
              expirationDate: new Date(expirationDate),
              amount,
            }}
          />
        )}
      </div>
    </div>
  );
})`
  position: relative;

  ${Icon} {
    display: block;
  }
  .Voucher {
    &-img {
      display: block;
    }
    &-index {
      color: white;
      position: absolute;
      top: 87px;
      left: 170px;
      font-size: 1.7em;
    }
    &-actions {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      margin: 16px 0;
    }
    &-status {
      ${font("mini")};
      color: ${color("text-dark")}99;
      flex: 1;
      &-date {
        color: ${color("primary")};
        font-weight: ${theme("font-weight-bold")};
      }
      &--used,
      &--used .Voucher-status-date {
        color: ${color("green")};
      }
    }
    &-download,
    &-upload {
      align-self: flex-start;
      border: 2px solid ${color("border-black")};
      border-radius: 100px;
      margin: 0 8px;
      padding: 4px;
      transition: border-color 0.3s ease;
      &:hover {
        border-color: ${color("primary")};
        --icon-hover: 1;
      }
    }
    ${is(({ downloaded, used }) => downloaded || used)`
      &-img,
      &-download,
      &-upload {
        filter: grayscale(100%);
        opacity: 0.3;
      }
      &-index, &-amount {
        color: #e5e5e5;
      }
    `}
  }
`;

export default function Vouchers() {
  const [vouchers, { loading }] = useVouchers();
  const [expiredVouchers] = useExpiredVouchers();
  const { isSubscriptionExpired, isEliteProPlus } = useSelfMetaData();

  const validVouchers = (vouchers?.length ? vouchers : []).filter(
    (voucher) => VOUCHERS_AMOUNTS.indexOf(voucher.amount) !== -1
  );

  // Si pas de premier bon, on considère que le montant par défaut d'un bon est de 100€
  const defaultAmountOfVoucher = validVouchers[0]?.amount || 100;

  // Si pas de premier bon, on considère que le montant par défaut d'un bon est de 100€
  const defaultVoucherExpiration = validVouchers[0]?.expirationDate;

  if (loading) {
    return <ContentLoading />;
  }

  return (
    <LoggedInTemplate title="Offres de remboursement">
      <ImageHeader
        image={header}
        image2x={header2x}
        imageMobile={headerMobile}
        imageMobile2x={headerMobile2x}
        icon="tools-line"
        label="Services"
      />
      <MainContent>
        <Headline title="Offres de remboursement">
          Chez Daikin, nous avons à coeur de faire fructifier votre business,
          <br />
          c'est pour cela que nous avons créé les offres de remboursement !
        </Headline>

        <Container>
          <TwoColumns
            aside={
              <PanelInfos
                label={`10 offres de remboursements de ${formatCurrency(
                  defaultAmountOfVoucher,
                  true
                )} TTC`}
                image={voucherPresentation}
              />
            }
          >
            <Paragraph>
              <strong>
                Pour vous permettre de proposer à votre client (particulier et
                professionnel) une aide financière supplémentaire et
                éventuellement de remporter un marché, sans rogner sur votre
                marge !
              </strong>
              <br />
              <br />
            </Paragraph>
            {isEliteProPlus ? (
              <>
                <SectionTitle>Comment ça marche ?</SectionTitle>
                <Paragraph>
                  Il vous suffit de télécharger ou d’envoyer directement par
                  e-mail les offres de remboursement présentes ci-dessous. Vous
                  pouvez les utiliser pour un ou plusieurs clients selon votre
                  souhait. Votre client n’a plus qu’à suivre les indications
                  présentes sur l'offre pour profiter de son remboursement.
                </Paragraph>
                <Paragraph>
                  <strong> Attention !</strong>
                  <br />
                  Les offres de remboursement ne peuvent être ni reconduites, ni
                  reportées sur un autre avantage du programme, ni remboursées
                  si non utilisées. Elles ne sont utilisables qu’une seule fois
                  {Boolean(defaultVoucherExpiration) && (
                    <> et expirent le {format(defaultVoucherExpiration)}</>
                  )}
                  .
                </Paragraph>
              </>
            ) : (
              <>
                <Paragraph>
                  <strong>
                    Si vous passez Elite Pro + l'année prochaine, vous
                    disposerez d'un nouvel avantage commercial :{" "}
                    <span className="color-primary">
                      les offres de remboursement !
                    </span>
                  </strong>
                </Paragraph>
                <img src={vouchersStackedImage} alt="" />
              </>
            )}
          </TwoColumns>
        </Container>
        {(Boolean(isEliteProPlus) || Boolean(validVouchers.length)) && (
          <Section>
            <Headline title="Téléchargez ou envoyez les offres de remboursement à vos clients !">
              La gestion et la bonne répartition des offres de remboursement
              Daikin à vos clients relève de votre responsabilité.{" "}
              <span className="color-primary">
                Soyez vigilants de ne pas distribuer la même offre à plusieurs
                clients.
              </span>
              {isSubscriptionExpired &&
                isEliteProPlus &&
                !vouchers?.length &&
                Boolean(expiredVouchers?.length) && (
                  <Tooltip
                    align="center"
                    content={
                      <CustomTooltipContent
                        text="
                  Vos nouvelles offres de remboursement sont en cours et
                  s'afficheront prochainement sur cette page"
                      />
                    }
                  />
                )}
            </Headline>
            {validVouchers.length > 0 ? (
              <VouchersList>
                {validVouchers.map((voucher, index) => (
                  <Voucher {...voucher} key={voucher.id} index={index + 1} />
                ))}
              </VouchersList>
            ) : (
              <Paragraph style={{ marginTop: "-48px", textAlign: "center" }}>
                Aucune offre n'est disponible
              </Paragraph>
            )}
            {isEliteProPlus && Boolean(expiredVouchers?.length) && (
              <PreviousYearBlock>
                <Paragraph
                  style={{ textAlign: "center", color: "#FFFFFF", margin: 0 }}
                >
                  <strong>Année passée :</strong>
                  <br />
                  {expiredVouchers.filter((v) => v.downloaded).length} /{" "}
                  {expiredVouchers?.length} offres téléchargées
                  <br />
                  {expiredVouchers.filter((v) => v.used).length} /{" "}
                  {expiredVouchers?.length} offres utilisées par vos clients
                </Paragraph>
              </PreviousYearBlock>
            )}
          </Section>
        )}
      </MainContent>
    </LoggedInTemplate>
  );
}

const PreviousYearBlock = styled.div`
  background: ${color("blue")};
  margin: 16px auto;
  max-width: 100%;
  padding: 32px;
  width: 540px;
`;
